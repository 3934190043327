import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import ProjectSeries from "../components/ProjectSeries"

import AutoformatThumbnail from "../images/project_thumbnails/auto_formatter.png"
import ImageGallery from "../components/ImageGallery"

const imgs = [
    {
        img: AutoformatThumbnail,
        alt: "An example formatted output",
    },
]

const AutoFormatterPage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>TA Tools - Autoformatter</h1>
            {ProjectSeries({
                name: "TA Tools",
                prevname: "Gradescope Grader",
                prevlink: "/gradescope",
                nextname: "Sample Assignment",
                nextlink: "/taassignment",
            })}
            <p>One of the worst tasks as a TA was grading. One of the worst grading tasks was grading tests. Student code frequently didn't compile (despite being allowed to use a compiler during the test). The web interface for displaying code was atrocious to type in so we let students write in an editor and paste their answer in. Of course the formatting was all kinds of funky after doing this. Finally we settled on file upload as the easiest solution. However, this came with a new problem: when grading you had to download and open the file for each student. You could download a zip file of all solutions, but with 300 students, finding the one you needed was frequently more challenging than downloading as you graded. In an attempt to alleviate these pain points, I tried to automate some of the grading process.</p>
            <p>The plan was simple: download all student code, sort in the same order that students appeared in the gradebook, format the student code in markdown, and render out the markdown into a PDF that could be used for grading. This was surprisingly easy and worked with little tweaking (aside from those students who uploaded compiled binaries rather than source code). However another problem was discovered: we often only cared about the student solution, a 10-20 line function buried in (sometimes several) a hundred lines of previous attempts, copy and pasted backups, test cases, etc.</p>
            <p>Never one to back down from a challenge, I set my sights on extracting just the student submission. Eventually, I got something decently reliable (after all, we knew the name and signature of the function we were looking for). In the case where it failed, we could always fall back on just displaying the entire submitted file if needed. With this, the next question was if we could integrate the concepts of the <a href="/gradescope">Gradescope Grader</a> to auto-grade the student code. It turns out that you can with two caveats:</p>
            <ol>
                <li>You want to be very sure that the points that are taken off should be taken off.</li>
                <li>You dont want to give points that are undeserved; this <em>will</em> lead to emails and annoyed students in office hours</li>
            </ol>
            <p>These are more or less the general concepts of unit testing anyway: no false positives and no false negatives. In the end only about one-third to one-half of the student submissions would compile, but those who did generally would pass all the test cases. For this subset of the class, grading became a breeze. I even tried to experimentally fit big-o if their code was otherwise perfect. Unfortunately, that meant that the assignment that were hand graded were generally rough: hard to discern and hard to give points to. This took a human toll on the people grading as the grading work became more intense. You win some you loose some.</p>
            <ImageGallery value={imgs}/>
            {FooterBar()}
        </div>
    )
}

export default AutoFormatterPage